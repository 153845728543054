import React from "react";

import security1 from "../../images/security-testing-orchestration/security1.svg";
import security2 from "../../images/security-testing-orchestration/security2.svg";
import icon1 from "../../images/security-testing-orchestration/icon1.svg";
import icon2 from "../../images/security-testing-orchestration/icon2.svg";
import icon3 from "../../images/security-testing-orchestration/icon3.svg";
import icon4 from "../../images/security-testing-orchestration/icon4.svg";
import icon5 from "../../images/security-testing-orchestration/icon5.svg";
import icon6 from "../../images/security-testing-orchestration/icon6.svg";

import card1 from "../../images/security-testing-orchestration/card1.svg";
import card2 from "../../images/security-testing-orchestration/card2.svg";
import card3 from "../../images/security-testing-orchestration/card3.svg";
import card4 from "../../images/security-testing-orchestration/card4.svg";
import card5 from "../../images/security-testing-orchestration/card5.svg";

export const STOFeatures = [
  {
    id: 1,
    image: (
      <img
        src={security1}
        loading="lazy"
        alt="Security from Code to Deployment"
        width={562}
      />
    ),
    content: (
      <>
        <h2>コードからデプロイまでのセキュリティー</h2>
        <p>
          パイプラインの適切な段階で適切なセキュリティースキャナーを自動的に実行（シフトレフトセキュリティー）し、安全なアプリケーションを迅速にデリバリー。ビジネスリスクを最小化します。
        </p>
        <h2>相関性のあるスキャナー結果</h2>
        <div className="mb-12">
          <span>
            Harnessは、時間のかかるスキャナー出力解析をエンジニアリングチームに代わって実行します。
          </span>
          <ul>
            <li>統一された優先順位の高い脆弱性修正リスト</li>
            <li>自動化された修復の検証</li>
            <li>追跡可能な除外リスト</li>
          </ul>
        </div>
        <h2>手作業による分析作業の時間短縮</h2>
        <p>
          Harness Security Testing
          Orchestrationは、セキュリティースキャナーから収集された膨大なデータを解析します。スキャナーのデータにインテリジェンスを適用し、優先順位の高い脆弱性リストと修正勧告を作成します。
        </p>
      </>
    ),
  },
  {
    id: 2,
    isReversed: true,
    image: (
      <img
        src={security2}
        alt="Standardization of security policies"
        loading="lazy"
        width={476}
        height={273}
      />
    ),
    content: (
      <>
        <h2>セキュリティーポリシーの標準化</h2>
        <p>
          Harnessに組み込まれたOpen Policy
          Agent（OPA）ポリシーを使用して、希望する全てのアプリケーションセキュリティースキャンが実行され、許容範囲内の結果が得られるようになります。一貫したアプリケーションセキュリティープロセスにより、ビジネスリスクを低減します。
        </p>
      </>
    ),
  },
];

export const whySTOCards = [
  {
    id: 1,
    title: "パイプラインのセキュリティーガバナンスの自動化",
    paragraph:
      "Harness Security Testing Orchestrationは、アプリケーションセキュリティースキャナーの結果を利用して、デプロイの可否を判断します。",
    icon: <img src={icon1} loading="lazy" width={65} alt="Why STO Icon 1" />,
    isSVG: true,
    height: "auto",
  },
  {
    id: 2,
    title: "プロアクティブセキュリティーフィードバック",
    paragraph:
      "CI/CDの全ての段階でアプリケーションセキュリティースキャンを実行し、安全性の高いコードとデプロイメントを促進します。",
    icon: <img src={icon2} loading="lazy" width={57} alt="Why STO Icon 2" />,
    isSVG: true,
    height: "auto",
  },
  {
    id: 3,
    title: "インテリジェントなスキャナー結果",
    paragraph:
      "Security Testing Orchestrationは、全てのスキャナ結果を正規化、重複排除、相関させ、インテリジェンスを適用して、修正すべき脆弱性の優先順位付けリストと修正勧告を作成します。",
    icon: <img src={icon3} loading="lazy" width={48} alt="Why STO Icon 3" />,
    isSVG: true,
    height: "auto",
  },
  {
    id: 4,
    title: "容易な監査コンプライアンス",
    paragraph:
      "Security Testing Orchestrationは包括的な監査ログを提供し、監査やコンプライアンス活動を迅速かつ容易に実施することができます。",
    icon: <img src={icon4} loading="lazy" width={47} alt="Why STO Icon 4" />,
    isSVG: true,
    height: "auto",
  },
  {
    id: 5,
    title: "CI/CDとのインテグレーション",
    paragraph:
      "CI/CDパイプラインにセキュリティーガードレールを組み込んで実施できます。アプリケーションのセキュリティーを向上させながら、高いベロシティーを実現します。",
    icon: <img src={icon5} loading="lazy" width={65} alt="Why STO Icon 5" />,
    isSVG: true,
    height: "auto",
  },
  {
    id: 6,
    title: "セキュリティービューの共有",
    paragraph:
      "エンジニアリングチームから経営陣まで、アプリケーションセキュリティーの状況について継続的に把握することができます。",
    icon: <img src={icon6} loading="lazy" width={45} alt="Why STO Icon 6" />,
    isSVG: true,
    height: "auto",
  },
];

export const gridCardsData = [
  {
    id: 1,
    title: "パイプラインテンプレートへのセキュリティーガードレールの追加",
    summary:
      "大規模な組織がアプリケーションセキュリティー機能を拡張するために必要な一貫性を提供します。",
    icon: (
      <img
        src={card1}
        loading="lazy"
        width={250}
        className="w-auto h-auto"
        alt="Security Guardrails Icon"
      />
    ),
    isSVG: true,
    height: "auto",
  },
  {
    id: 2,
    title: "セキュリティーポリシーの設定",
    summary:
      "リスク許容度を調整し、速度と安全性の高いアプリケーションサービスの配信の適切なバランスを確保できます。",
    icon: (
      <img
        src={card2}
        loading="lazy"
        width={250}
        className="w-auto h-auto"
        alt="Security policy settings Icon"
      />
    ),
    isSVG: true,
    height: "auto",
  },
  {
    id: 3,
    title: "スキャナーインテグレーションの設定",
    summary:
      "コードとビルドの段階でスキャンを開始し、本番環境に至るまで、全ての層のセキュリティーポリシー準拠を確認できます。",
    icon: (
      <img
        src={card3}
        loading="lazy"
        width={250}
        className="w-auto h-auto"
        alt="Configure scanner integration Icon"
      />
    ),
    isSVG: true,
    height: "auto",
  },
  {
    id: 4,
    title: "CI/CDパイプラインの実行",
    summary: "ソフトウェアをより速く、安全にデリバリーできるようになります。",
    icon: (
      <img
        src={card4}
        loading="lazy"
        width={250}
        className="w-auto h-auto"
        alt="Run CI/CD pipelines Icon"
      />
    ),
    isSVG: true,
    height: "auto",
  },
  {
    id: 5,
    title: "セキュリティー脆弱性の修正",
    summary:
      "どの脆弱性をどのように修正すべきかを、即座に理解することができます。",
    icon: (
      <img
        src={card5}
        loading="lazy"
        width={250}
        className="w-auto h-auto"
        alt="Security vulnerability fixes Icon"
      />
    ),
    isSVG: true,
    height: "auto",
  },
];
