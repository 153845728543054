import React from "react";
import FeatureCard from "../../common/featureCard";
import { STOFeatures } from "../contents";

const Features = () => {
  return (
    <div className="STO-features">
      {STOFeatures.map((feature) => (
        <FeatureCard
          image={feature?.image}
          content={feature?.content}
          key={feature?.id}
          isReversed={feature?.isReversed}
        />
      ))}
    </div>
  );
};

export default Features;
