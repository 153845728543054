import React, { useState } from "react";
import Container from "@layout/container";
import HeroBanner from "@components/common/heroBanner";
import VideoLightBox from "@components/common/videoLightBox/VideoLightBox";
import Meta from "@components/seo/Meta";
import { graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import GridCards from "@components/common/gridCards/GridCards";
import { whySTOCards } from "@components/security-testing-orchestration/contents";
import ThreeColumnsGridCards from "@components/common/gridCards/ThreeColumnsGridCards";
import { gridCardsData } from "@components/security-testing-orchestration/contents";
import Features from "@components/security-testing-orchestration/Features/Features";

const SecurityTestingOrchestration = (props) => {
  const [isVideoPlaying, toggleVideoPlaying] = useState(false);
  const siteUrl = props?.data?.allSite?.nodes[0].siteMetadata?.siteUrl;
  const metaData = {
    title: `Harness Security Testing Orchestration｜Harness正規販売代理店 DXable`,
    description: `Harness Security Testing Orchestrationは、優先順位付けされたセキュリティー脆弱性データを用いて、開発者の能力を強化します。`,
    url: `${siteUrl}${props.path}`,
  };
  return (
    <>
      <Meta meta={metaData} />
      <Container>
        <HeroBanner
          title={<BannerTitle />}
          paragraph={<BannerParagraph />}
          media={
            <MediaVideo
              isVideoPlaying={isVideoPlaying}
              toggleVideoPlaying={toggleVideoPlaying}
            />
          }
        />
        <Features />
        <GridCards
          title={"Harness Security Testing Orchestrationを活用する理由"}
          cards={whySTOCards}
          bg="#fff"
        />
        <ThreeColumnsGridCards
          title="Harness Security Testing Orchestrationの仕組み"
          cards={gridCardsData}
          bg="#fff"
        />
      </Container>
    </>
  );
};

const BannerTitle = () => {
  return <span>Harness Security Testing Orchestration</span>;
};

const BannerParagraph = () => {
  return (
    <>
      <span className="mb-4">安全なアプリケーションの迅速なデリバリー。</span>
      <span>
        優先順位付けされたセキュリティー脆弱性データで開発者を強化：デプロイメント速度を維持し、手戻りを最小限に抑えながら、安全性の高いアプリケーションのデリバリーを実現します。
      </span>
    </>
  );
};

const MediaVideo = ({ isVideoPlaying, toggleVideoPlaying }) => {
  return (
    <>
      <button onClick={() => toggleVideoPlaying(!isVideoPlaying)}>
        <StaticImage
          src="../../images/security-testing-orchestration/SecurityTestingOrchestrationBanner.svg"
          layout="fullWidth"
          loading="eager"
          alt="Security Testing Orchestration Page Panner"
          className="w-full hover:cursor-pointer"
          quality={100}
        />
      </button>
      {isVideoPlaying && (
        <VideoLightBox
          src={"https://www.youtube.com/embed/6J7zvfdQMV4"}
          title={"Security Testing Orchestration in 5 mins"}
          toggleVideoPlaying={toggleVideoPlaying}
          isVideoPlaying={isVideoPlaying}
        />
      )}
    </>
  );
};

export const SecurityTestingOrchestrationPageData = graphql`
  query fetchSecurityTestingOrchestrationPageData {
    allSite {
      nodes {
        siteMetadata {
          siteUrl
          assetsBaseUrl
        }
      }
    }
  }
`;

export default SecurityTestingOrchestration;
